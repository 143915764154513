<template>
    <div class="ku-bbs-right-hot-section">
        <div class="ku-bbs-right-header">热门文章</div>
        <ul class="ku-bbs-right-hot">
            <li
                class="ku-bbs-hot-item"
                v-for="(item, index) in hotList"
                :key="item.topicId"
                @click="go2Detail(item)"
                v-track-event.click="'热门文章, 点击'"
            >
                <a class="ku-bbs-hot-text-wraper">
                    <i :class="getHotCls(index)"></i>
                    <span class="ku-bbs-hot-text">{{ item.title }}</span>
                    <span class="ku-bbs-left-fire">
                        {{ $num(item.viewCount) }}
                        <i class="ku-bbs-fire-icon" v-if="index < 3"></i>
                        <i class="ku-bbs-fire-icon-not" v-else></i>
                    </span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "ku-article-hot",
    props: {
        postItems: {
            type: Array,
            default() {
                return []
            },
        },
    },
    data() {
        const list = this.postItems || []
        return {
            hotList: list,
        }
    },
    watch: {
        postItems(val) {
            this.hostList = val || []
        },
    },
    methods: {
        go2Detail(row) {
            this.$emit("go-detail", row)
            // if (row.briefContent || (row.imageList && row.imageList.length > 0)) {
            //     this.$emit("go-detail", row)
            // } else {
            //     this.$message.error("当前内容已被作者下架！")
            // }
        },
        getHotCls(index) {
            if (index < 10) {
                return `ku-bbs-hot-${index}`
            } else {
                return "ku-bbs-hot-nums"
            }
        },
    },
}
</script>

<style lang="scss">
.ku-bbs-right-hot-section {
    background-color: #fff;
    padding-bottom: 20px;
    border-radius: 10px;

    .ku-bbs-right-header {
        padding: 15px 14px;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
        color: #222222;
    }

    .ku-bbs-hot-text-wraper {
        display: flex;
        flex-wrap: nowrap;
        padding: 5px 14px;
        font-size: 14px;
        font-weight: 400;
        color: #222222;
    }

    .ku-bbs-hot-text {
        max-width: 150px;
        overflow: hidden;
        /*超出部分隐藏*/
        white-space: nowrap;
        /*禁止换行*/
        text-overflow: ellipsis;

        /*省略号*/
        &:hover {
            color: #24c68b;
        }
    }

    .ku-bbs-hot-item {
        margin-bottom: 10px;
        cursor: pointer;
    }

    .ku-bbs-hot-nums {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        text-align: center;
        font-size: 20px;
        margin-top: -7px;
        font-style: normal;
        color: #999999;
        font-weight: 600;
    }

    .ku-bbs-hot-0 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/diyi.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-hot-1 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/dier.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-hot-2 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/disan.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-hot-3 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/si.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-hot-4 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/wu.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-hot-5 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/liu.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-hot-6 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/qi.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-hot-7 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/ba.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-hot-8 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/jiu.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-hot-9 {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url("@/assets/bbs/shi.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-left-fire {
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        align-self: flex-end;
        margin-right: 0;
        margin-left: auto;
    }

    .ku-bbs-mianjing {
        background: url("@/assets/bbs/mianjing.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-xiangmu {
        background: url("@/assets/bbs/xiangmu.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-xuexi {
        background: url("@/assets/bbs/xeuxi.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-zhishi {
        background: url("@/assets/bbs/zhishi.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-huati {
        background: url("@/assets/bbs/huati.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-fire-icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url("@/assets/bbs/re.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-fire-icon-not {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url("@/assets/bbs/huire.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-card-zan {
        background: url("@/assets/icons/dianzan.svg") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-card-zan-green {
        background: url("@/assets/icons/dianzan-green.svg") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-card-store {
        background: url("@/assets/icons/shoucang.svg") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-card-store-green {
        background: url("@/assets/bbs/shoucang.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-card-share {
        background: url("@/assets/bbs/zhuanfa.png") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-card-view {
        background: url("@/assets/icons/liulan.svg") center center no-repeat;
        background-size: 100% 100%;
    }

    .ku-bbs-card-more {
        background: url("@/assets/bbs/gengduo.png") center center no-repeat;
        background-size: 100% 100%;
    }
}
</style>
