import request from "@/utils/request"

// 验证码登录登录方法
export function login({ phone, code }) {
    return request({
        url: "/auth/codeLogin",
        method: "post",
        data: { phone, code, registerChannel: "3" },
    })
}

// 短信验证码
export function getSysPhoneCode({ phone }) {
    return request({
        url: "/friend/semiLogin/sendCode",
        params: { phone },
        method: "get",
        timeout: 20000,
    })
}

// 验证老的手机号
export function validatePhone({ phone, code }) {
    return request({
        url: "/friend/semiLogin/checkCode",
        params: { phone, code },
        method: "get",
    })
}

// 修改手机号
export function updateUserPhone({ phone, code }) {
    return request({
        url: "/friend/user/updatePhone",
        method: "post",
        data: { phone, code },
    })
}

// 发送微信的code给后端
export function sendWeixinCode(code) {
    return request({
        url: "/auth/wxLogin",
        method: "post",
        data: { code, registerChannel: "3" },
    })
}

// 获取用户信息（简单版）
export function getInfo() {
    return request({
        url: "/friend/user/getUserInfo",
        method: "get",
    })
}

// 退出方法
export function logout() {
    return request({
        url: "/auth/logout",
        method: "DELETE",
    })
}

// 保存个人信息
export function updateUserInfo(params) {
    return request({
        url: "/friend/user/update",
        method: "post",
        data: { ...params },
    })
}

export function uploadAvatar(data) {
    return request({
        url: "/file/upload",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        method: "post",
        data: data,
    })
}

// 获取用户信息（首页）
export function getuserInfoSimple() {
    return request({
        url: "/friend/user/getSimpleUserInfo",
        params: {},
        method: "get",
    })
}
// 教务系统授权登录
export function authLogin(params = {}) {
    return request({
        url: "/auth/tms/jump",
        method: "get",
        params: params,
    })
}

// 获取用户信息V2
export function getUserInfoV2(params) {
    return request({
        url: "/friend/user/semiLogin/getUserInfoV2",
        method: "get",
        params: params,
    })
}
