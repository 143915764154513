import request from "@/utils/request"

// 用户动态删除
export function dynamicDelete(data) {
    return request({
        url: "/friend/user/dynamic/delete",
        method: "post",
        data: data,
    })
}

// 修改动态可见范围 /friend/user/dynamic/updateVisibility
export function updateVisibility(data) {
    return request({
        url: "/friend/user/dynamic/updateVisibility",
        method: "post",
        data: data,
    })
}

// 获取用户动态话题列表

export function getUserTopicList(params) {
    return request({
        url: "/friend/user/semiLogin/topic-type/list",
        method: "get",
        params: params,
    })
}

// 获取用户动态详情
export function getUserDynamicDetail(params) {
    return request({
        url: "/friend/user/dynamic/detail",
        method: "get",
        params: params,
    })
}

// 获取用户的未读消息数量
export function getUserUnreadMsgNum(params) {
    return request({
        url: "/friend/user/message/semiLogin/unread-count",
        method: "get",
        params: params,
    })
}

// 修改用户简介
export function updateUserIntroduce(data) {
    return request({
        url: "/friend/user/updateIntroduce",
        method: "post",
        data: data,
    })
}

// getUserServiceList 获取用户服务列表
export function getUserServiceList(userId) {
    return request({
        url: "/friend/user/semiLogin/getUserService",
        method: "get",
        params: { userId },
    })
}
