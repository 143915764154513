<script>
import { sendWeixinCode } from "@/api/login"
import { setToken } from "@/utils/auth"
export default {
    name: "cms-reload",
    created() {
        const { query } = this.$route
        const { redirect = "/ship", code, ...rest } = query || {}
        if (redirect && code) {
            return this.getWweixinCode(query)
        }
        this.$router.replace({
            path: redirect,
            query: { ...rest },
        })
    },
    render: function (h) {
        return h()
    },
    methods: {
        getPath(redirect) {
            const index = redirect.indexOf("=")
            const path = redirect.slice(index + 1)
            if (path && path !== "") {
                return path
            } else {
                return "/ship"
            }
        },
        getWweixinCode(query) {
            const { redirect, code, ...rest } = query || {}
            return sendWeixinCode(code)
                .then(resp => {
                    const { access_token, isFirstLogin } = resp.data
                    setToken(access_token)
                    this.$store.commit("SET_TOKEN", access_token)
                    this.$store.commit("SET_SHOWREGIST", isFirstLogin)
                    this.$router.replace({
                        path: this.getPath(redirect),
                        query: { ...rest },
                    })
                })
                .catch(err => {
                    this.$message({
                        message: err.msg,
                        type: "error",
                    })
                    this.$store.commit("SET_SHOWREGIST", false)
                    this.$router.replace({
                        path: this.getPath(redirect),
                        query: { ...rest },
                    })
                })
        },
    },
}
</script>
