<template>
    <div class="ku-forum-layout">
        <div class="ku-forum-layout-header">
            <div class="ku-forum-layout-bg"></div>
            <div class="ku-forum-layout-nav">
                <ku-navbar></ku-navbar>
            </div>
        </div>
        <div class="ku-forum-layout-body">
            <div class="ku-forum-layout-body-section">
                <div class="ku-forum-layout-body-left">
                    <el-menu class="ku-forum-layout-body-menu" :router="true" :default-active="activeMenu">
                        <el-menu-item class="ku-article-bread-item" v-for="item in leftTopics" :key="item.id"
                            :index="item.path" v-track-event.click="'论坛,' + item.name + ',点击'">
                            <div class="ku-article-bread-item-icon" v-if="item.path == activeMenu">
                                <img :src="item.active" />
                            </div>
                            <div class="ku-article-bread-item-icon" v-else><img :src="item.icon" /></div>
                            <div class="ku-article-bread-item-text">{{ item.name }}</div>
                        </el-menu-item>
                    </el-menu>
                </div>
                <div class="ku-forum-layout-body-right">
                    <KeepAlive include="home-view">
                        <router-view :key="$route.fullPath"></router-view>
                    </KeepAlive>
                </div>
            </div>
        </div>
        <!-- 
        :style="{
            height: $route.name == 'articleList' ? '1px' : '260px',
            overflow: 'hidden',
            opacity: $route.name == 'articleList' ? 0 : 1,
        }"
        -->
        <div class="ku-forum-layout-footer" :style="{
                        height:'1px',
                        overflow: 'hidden',
                        opacity: 0,
                    }">
            <div class="ku-forum-layout-footer-body">
                <div class="ku-forum-layout-footer-top">
                    <div class="ku-forum-layout-footer-top-item ku-forum-layout-footer-top-item-first">
                        <p class="ku-forum-layout-text">西安库学长信息技术有限公司</p>
                        <p class="ku-forum-layout-text">客服热线：</p>
                        <p class="ku-forum-layout-value-phone">029 8821 8621</p>
                        <p class="ku-forum-layout-text">公司地址：</p>
                        <p class="ku-forum-layout-value">陕西省西安市高新区沣惠南路34号新长安广场c座11层11106室</p>
                    </div>
                    <div class="ku-forum-layout-footer-top-item ku-forum-layout-footer-top-item-secd">
                        <p class="ku-forum-layout-text private-text" @click.stop="gotoPrivate('private')">隐私协议</p>
                        <p class="ku-forum-layout-text private-text" @click.stop="gotoPrivate('service')">服务条款</p>
                    </div>
                    <div class="ku-forum-layout-footer-top-item ku-forum-layout-footer-top-item-flex">
                        <div class="ku-forum-layout-weixin-item">
                            <img src="@/assets/w3-footer.jpg" />
                            <div>添加客服微信</div>
                            <div>了解更多内容</div>
                        </div>
                        <div class="ku-forum-layout-weixin-item">
                            <img class="ku-forum-layout-weixin-cycle" src="@/assets/public.jpg" />
                            <div>扫码关注</div>
                            <div>KU学长公众号</div>
                        </div>
                    </div>
                </div>
                <div class="ku-forum-layout-footer-bottom">
                    西安库学长信息技术有限公司
                    <a class="ku-forum-layout-footer-gov" href="https://beian.miit.gov.cn/" target="_blank">
                        陕ICP备2023009067号-1
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import salary from "@/assets/article/salary.png"
import salaryactive from "@/assets/article/salary-active.png"
import recommend from "@/assets/article/recommend.png"
import recommendactive from "@/assets/article/recommend-active.png"
import resume from "@/assets/article/resume.png"
import resumeactive from "@/assets/article/resume-active.png"
import fulihuodong from "@/assets/article/fulihuodong.png"
import fulihuodongactive from "@/assets/article/fulihuodongactive.png"
import gengduo1 from "@/assets/article/gengduo1.png"
import gengduo1active from "@/assets/article/gengduo1active.png"
import jinli from "@/assets/article/jinli.png"
import jinliactive from "@/assets/article/jinliactive.png"
import gengduo from "@/assets/article/gengduo.png"
import mianjing from "@/assets/article/mianjing.png"
import richang from "@/assets/article/richang.png"
import xiangmu from "@/assets/article/xiangmu.png"
import xuexi from "@/assets/article/xuexi.png"
import shijianzhou from "@/assets/article/shijianzhou.png"
import shoucang from "@/assets/article/shoucang.png"
import gengduoactive from "@/assets/article/gengduo-active.png"
import mianjingactive from "@/assets/article/mianjing-active.png"
import richangactive from "@/assets/article/richang-active.png"
import xiangmuactive from "@/assets/article/xiangmu-active.png"
import xuexiactive from "@/assets/article/xuexi-active.png"
import shijianzhouactive from "@/assets/article/shijianzhou-active.png"
import shoucangactive from "@/assets/article/shoucang-active.png"
import xzxg from "@/assets/article/xzxg.png"
import xxjy from "@/assets/article/xxjy.png"
import lsjy from "@/assets/article/lsjy.png"
import xzxgactive from "@/assets/article/xzxgactive.png"
import xxjyactive from "@/assets/article/xxjyactive.png"
import lsjyactive from "@/assets/article/lsjyactive.png"
import KuNavbar from "@/components/ku-navbar/ku-navbar"
import { getTopicTypeList } from "@/api/article"
export default {
    name: "ku-forum-layout",
    components: { KuNavbar },
    data() {
        return {
            isCreatePage: false,
            leftTopics: [],
        }
    },
    computed: {
        activeMenu() {
            const route = this.$route
            const { path } = route
            if (path == "/bbs" || path == '/bbs/') {
                // return "/bbs/interview/0"
                return "/bbs/interview/0"
            }
            return path
        },
    },
    created() {
        this.getTopicList()
    },
    methods: {
        getTopicList() {
            return getTopicTypeList().then(resp => {
                this.leftTopics = (resp.data || []).map(item => {
                    const { topicType, topicTypeName } = item
                    if (topicType == 999) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: mianjing,
                            active: mianjingactive,
                            path: `/bbs/all/${topicType}`,
                            meta: { title: "论坛", activeMenu: "/bbs", needLogin: true }
                        }
                    }
                    if (topicType == 0) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: mianjing,
                            active: mianjingactive,
                            path: `/bbs/interview/${topicType}`,

                        }
                    }
                    if (topicType == 1) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: gengduo,
                            active: gengduoactive,
                            path: `/bbs/study/${topicType}`,
                        }
                    }
                    if (topicType == 2) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: xiangmu,
                            active: xiangmuactive,
                            path: `/bbs/tech/${topicType}`,
                        }
                    }
                    if (topicType == 3) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: richang,
                            active: richangactive,
                            path: `/bbs/daily/${topicType}`,
                        }
                    }
                    if (topicType == 101) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: fulihuodong,
                            active: fulihuodongactive,
                            path: `/bbs/action/${topicType}`,
                        }
                    }
                    if (topicType == 6) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: jinli,
                            active: jinliactive,
                            path: `/bbs/refuge/${topicType}`,
                        }
                    }
                    if (topicType == 4) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: xuexi,
                            active: xuexiactive,
                            path: `/bbs/knowledge/${topicType}`,
                        }
                    }
                    if (topicType == 5) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: gengduo1,
                            active: gengduo1active,
                            path: `/bbs/more/${topicType}`,
                        }
                    }
                    if (topicType == 99) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: shoucang,
                            active: shoucangactive,
                            path: `/bbs/store/${topicType}`,
                        }
                    }
                    if (topicType == 98) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: xzxg,
                            active: xzxgactive,
                            path: `/bbs/faq/${topicType}`,
                        }
                    }
                    if (topicType == 97) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: xxjy,
                            active: xxjyactive,
                            path: `/bbs/learn/${topicType}`,
                        }
                    }
                    if (topicType == 96) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: lsjy,
                            active: lsjyactive,
                            path: `/bbs/recommend/${topicType}`,
                        }
                    }
                    if (topicType == 100) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: shijianzhou,
                            active: shijianzhouactive,
                            path: `/bbs/timeline/${topicType}`,
                        }
                    }
                    if (topicType == 8) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: resume,
                            active: resumeactive,
                            path: `/bbs/resume/${topicType}`,
                        }
                    }
                    if (topicType == 10) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: recommend,
                            active: recommendactive,
                            path: `/bbs/recommend/${topicType}`,
                        }
                    }
                    if (topicType == 14) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: salary,
                            active: salaryactive,
                            path: `/bbs/salary/${topicType}`,
                        }
                    }
                    return {
                        name: topicTypeName,
                        id: topicType,
                        icon: gengduo,
                        active: gengduoactive,
                        meta: { title: "论坛", activeMenu: "/bbs", needLogin: true }
                    }
                })
            })
        },
        gotoPrivate(path) {
            const route = this.$router.resolve({
                path: `/${path}`,
            })
            window.open(route.href)
        },
    },
}
</script>

<style lang="scss" scoped>
.ku-forum-layout-body-section {
    display: flex;
    width: 1200px;
    margin: 30px auto 90px;

    .ku-forum-layout-body-left {
        width: 180px;

        .ku-forum-layout-body-menu {
            border: none;
            background-color: #fff;
            border-radius: 10px;
            padding: 16px 7px 16px 9px;
        }

        .ku-article-bread-item-icon {
            width: 16px;
            height: 16px;
            margin-right: 15px;

            img {
                width: 100%;
                height: 100%;
                vertical-align: top;
            }
        }

        .ku-article-bread-item-text {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            white-space: nowrap;
        }

        .ku-article-bread-item {
            display: flex;
            align-items: center;
            padding: 11px 17px;
            cursor: pointer;
            border-radius: 5px;
            height: auto;
            line-height: normal;

            &:hover {
                background-color: #f7f7f7;
            }

            &.is-active {
                background-color: #e9f9f3;

                .ku-article-bread-item-text {
                    color: #24c68b;
                }
            }
        }
    }

    .ku-forum-layout-body-right {
        flex: 1;
    }
}

.ku-forum-layout {
    background-color: #f7f7f7;

    .ku-forum-layout-bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #243d41;
    }

    .ku-forum-layout-header {
        position: relative;
        height: 64px;
    }

    .ku-forum-layout-nav {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
    }

    .ku-forum-layout-body {
        min-height: calc(100vh - 64px);
    }

    .ku-forum-layout-footer {
        background: #313438;
    }

    .ku-forum-layout-footer-body {
        width: 1200px;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .ku-forum-layout-footer-top {
        display: flex;
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }

    .ku-forum-layout-footer-top-item-flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ku-forum-layout-weixin-item {
        width: 100px;
        text-align: center;

        img {
            width: 100px;
            height: 100px;
        }
    }

    .ku-forum-layout-value-phone {
        margin-bottom: 20px;
    }

    .ku-forum-layout-weixin-item+.ku-forum-layout-weixin-item {
        margin-left: 100px;
    }

    .ku-forum-layout-footer-top-item-first {
        margin-right: 118px;
    }

    .ku-forum-layout-footer-top-item-secd {
        margin-right: 338px;
    }

    .ku-forum-layout-text {
        white-space: nowrap;
    }

    .ku-forum-layout-text+.ku-forum-layout-text {
        margin-top: 20px;
    }

    .ku-forum-layout-weixin-cycle {
        /*border-radius: 50px;*/
    }

    .private-text {
        cursor: pointer;
    }

    .ku-forum-layout-footer-bottom {
        color: #939496;
    }

    .ku-forum-layout-footer-gov {
        font-size: 14px;
        font-weight: 400;
        color: #939496;
    }
}
</style>
