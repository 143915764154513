import { render, staticRenderFns } from "./ku-article-card.vue?vue&type=template&id=4ab3d7c0&scoped=true&"
import script from "./ku-article-card.vue?vue&type=script&lang=js&"
export * from "./ku-article-card.vue?vue&type=script&lang=js&"
import style0 from "./ku-article-card.vue?vue&type=style&index=0&id=4ab3d7c0&prod&lang=css&"
import style1 from "./ku-article-card.vue?vue&type=style&index=1&id=4ab3d7c0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../soft/nodejs/node_global/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab3d7c0",
  null
  
)

export default component.exports