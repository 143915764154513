<template>
    <div class="ku-login">
        <el-dialog
            top="0px"
            :visible.sync="visible"
            :show-close="true"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center
            class="ku-login-dialog-centor"
            @open="clearForm"
        >
            <div class="login-by-phone" v-show="isShowLoginType('phone')">
                <div class="ku-login-switch">
                    <div class="ku-login-switch-body">
                        <img
                            class="ku-login-wiexin-img"
                            src="@/assets/icons/erweima.png"
                            @click="changeLoginType('weixin')"
                        />
                    </div>
                </div>
                <div class="ku-login-header">我要找工作</div>
                <el-form
                    :model="loginForm"
                    status-icon
                    :rules="rules"
                    ref="loginForm"
                    label-width="0"
                    class="demo-ruleForm"
                >
                    <el-form-item prop="phone">
                        <el-input type="text" v-model="loginForm.phone" autocomplete="off" placeholder="输入手机号">
                            <span class="cms-login-phone" slot="prefix"></span>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="code">
                        <el-input
                            type="text"
                            v-model="loginForm.code"
                            autocomplete="off"
                            placeholder="输入验证码"
                            @keyup.enter.native="handleLogin"
                        >
                            <span class="cms-login-getcode" slot="suffix" @click="getPhoneCode">
                                {{ count == 0 ? "获取验证码" : `${count}s` }}
                            </span>
                        </el-input>
                    </el-form-item>
                    <div class="ku-login-footer">
                        <el-button
                            class="ku-login-footer-btn"
                            type="primary"
                            @click.native.prevent="handleLogin"
                            v-track-event.click="'登录/注册, 点击'"
                        >
                            登录/注册
                        </el-button>
                    </div>
                    <div class="cms-login-form-private">
                        登录/注册即代表同意
                        <a class="cms-login-form-private-item" @click.stop="gotoPrivate('service')">服务条款</a>
                        和
                        <a class="cms-login-form-private-item" @click.stop="gotoPrivate('private')">隐私协议</a>
                    </div>
                </el-form>
            </div>
            <div class="login-by-weixin" v-show="isShowLoginType('weixin')">
                <div class="ku-login-switch">
                    <div class="ku-login-switch-body">
                        <img
                            class="ku-login-wiexin-img"
                            src="@/assets/icons/diannao.png"
                            @click="changeLoginType('phone')"
                        />
                        <!--<span class="ku-login-switch-tag">微信快速注册</span>-->
                    </div>
                </div>
                <div class="ku-login-header">微信登录</div>
                <div class="cms-weixin-coder-image">
                    <div class="cms-weixin-coder-block qr-code" id="qrcode"></div>
                </div>
                <div class="cms-login-phone-login">
                    <span class="cms-login-phone-text">
                        登录遇到问题？
                        <span class="cms-login-phone-text-item" @click="changeLoginType('weixin')">试试刷新</span>
                        或者
                        <span class="cms-login-phone-text-item" @click="changeLoginType('phone')">手机号登录</span>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getSysPhoneCode } from "@/api/login"
import { tansParams } from "@/utils/request"
import { pathToRegexp } from "path-to-regexp"
import "./wxLogin"

export default {
    name: "ku-login",
    data() {
        return {
            loading: false,
            loginForm: {
                phone: "",
                code: "",
            },
            rules: {
                phone: [
                    {
                        required: true,
                        pattern: /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "change",
                    },
                ],
                code: [
                    {
                        required: true,
                        pattern: /^\d{6}$/,
                        trigger: "change",
                        message: "请输入6位验证码",
                    },
                ],
            },
            count: 0,
            loginType: "phone",
        }
    },
    computed: {
        visible: {
            get() {
                return this.$store.getters.isShowLoginFrom
            },
            set(val) {
                this.$store.commit("SET_SHOWLOGIN", val)
            },
        },
    },
    methods: {
        clearForm() {
            const { loginForm } = this.$refs
            loginForm && loginForm.resetFields()
            this.loginType = "phone"
            this.count = 0
        },
        handleLogin() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    this.loading = true
                    this.$store
                        .dispatch("Login", this.loginForm)
                        .then(() => {
                            return this.$store.dispatch("GetInfo")
                        })
                        .then(() => {
                            this.loading = false
                            window.clearTimeout(this.timer)
                            this.count = 60
                            this.loginForm = {
                                phone: "",
                                code: "",
                            }
                            this.$store.commit("SET_SHOWLOGIN", false)
                        })
                        .then(() => {
                            const { path } = this.$route
                            // const re = pathToRegexp("/index/:id")
                            // const match = re.exec(path)
                            if (
                                path == "/index" ||
                                path == "/ship" ||
                                path == "/app/request" ||
                                path == "/app/store"
                                // match
                            ) {
                                this.$router.push({
                                    path: "/reload",
                                    query: {
                                        redirect: path,
                                    },
                                })
                            }
                        })
                        .catch(() => {
                            this.loading = false
                        })
                }
            })
        },
        getPhoneCode() {
            if (!this.loginForm.phone) {
                this.$message({
                    message: "请输入手机号码",
                    type: "warning",
                })
                return
            }
            if (!/^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.loginForm.phone)) {
                this.$message({
                    message: "请输入正确的手机号码",
                    type: "warning",
                })
                return
            }
            if (this.count != 0) {
                return
            }
            getSysPhoneCode({
                phone: this.loginForm.phone,
            }).then(() => {
                this.$message({
                    message: "验证码已经发送成功，请查收",
                    type: "warning",
                })
                this.count = 60
                this.countDown()
            })
        },
        countDown() {
            if (this.count != 0) {
                this.timer = setTimeout(() => {
                    this.count--
                    if (this.count < 0) {
                        this.count = 0
                    }
                    this.countDown()
                }, 1000)
            }
        },
        crateWeixinUrl() {
            const data = tansParams({
                redirect: window.location.pathname,
            })
            new window.WxLogin({
                self_redirect: false,
                id: "qrcode",
                appid: "wx82d1a483d1797961",
                scope: "snsapi_login",
                redirect_uri: `https://kuxuezhang.com/reload?${data}`,
                state: Math.ceil(Math.random() * 10000),
                style: "",
                href: window.location.origin + "/weixin-block.css",
            })
        },
        changeLoginType(type) {
            this.loginType = type
            if (type == "weixin") {
                this.crateWeixinUrl()
            }
        },
        isShowLoginType(type) {
            return this.loginType == type
        },
        gotoPrivate(path) {
            const route = this.$router.resolve({
                path: `/${path}`,
            })
            window.open(route.href)
        },
    },
}
</script>

<style lang="scss">
.ku-login-dialog-centor {
    & > .el-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 430px;
        height: 340px;
        transform: translate(-50%, -50%);
        border-radius: 30px;
        margin: 0 !important;

        .el-dialog__body {
            padding: 40px 0 20px;
            height: 280px;
        }

        .el-dialog__header {
            height: 0;
            padding: 0;
        }
    }

    .el-input__inner {
        /*height: 60px;*/
        /*line-height: 60px;*/
        border-radius: 10px;
        border: 1px solid #979797;
        font-size: 14px;
    }

    iframe {
        height: 200px;
        width: 100%;
    }
}
</style>

<style lang="scss" scoped>
.ku-login {
    .ku-login-switch {
        position: relative;
        height: 0;
    }

    .ku-login-switch-body {
        position: absolute;
        top: -27px;
        left: 15px;
        display: flex;
    }

    .ku-login-wiexin-img {
        width: 40px;
        height: 40px;
    }

    .ku-login-switch-tag {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        padding: 2px 8px 4px 14px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        align-self: center;
        margin-left: 22px;
    }

    .ku-login-header {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #222222;
        margin: 0 47px;
        margin-bottom: 20px;
    }

    .cms-login-phone {
        display: inline-block;
        width: 12px;
        margin-top: -4px;
        margin-left: 6px;
        height: 50px;
        background: url("@/assets/icons/sjouji.png") no-repeat left center;
        background-size: contain;
    }

    .cms-login-getcode {
        display: inline-block;
        width: 87px;
        padding-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #24c68b;
        line-height: 20px;
        cursor: pointer;
        user-select: none;
        border-left: 1px solid #e4e7ed;
    }

    .ku-login-footer {
        margin-top: 40px;
    }

    .ku-login-footer-btn {
        width: 100%;
        background: #24c68b;
        border-radius: 10px;
        font-size: 16px;
        height: 38px;
        font-weight: 500;
        color: #ffffff;
        border: none;
    }

    .login-by-weixin {
        text-align: center;
    }

    .cms-weixin-coder-image {
    }

    .cms-weixin-coder-block {
        margin: 0 auto;
    }

    .cms-login-phone-login {
        margin-top: 8px;
    }

    .cms-login-phone-text-item {
        color: #07c060;
        cursor: pointer;
    }

    .cms-login-form-private {
        font-size: 14px;
        margin-top: 20px;
    }

    .cms-login-form-private-item {
        color: #07c060;
        cursor: pointer;
        font-size: 14px;
    }

    .demo-ruleForm {
        width: 306px;
        margin: auto;
    }
}
</style>
