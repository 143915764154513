<template>
    <div class="ku-info">
        <el-dialog
            top="0px"
            :visible.sync="visible"
            :show-close="true"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center
            class="ku-login-dialog-info"
            @open="clearForm"
        >
            <div class="ku-login-header">找组织</div>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
                <el-form-item prop="schoolId">
                    <el-select
                        style="width: 100%"
                        v-model="ruleForm.schoolId"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入学校名称"
                        :remote-method="text => this.query(text, 'school')"
                    >
                        <el-option
                            v-for="item in schoolOpts"
                            :key="item.schoolId"
                            :label="item.schoolName"
                            :value="item.schoolId"
                        ></el-option>
                    </el-select>
                    <!--<el-autocomplete-->
                    <!--style="width: 100%"-->
                    <!--v-model="ruleForm.school"-->
                    <!--:fetch-suggestions="(queryString, cb) => querySearchAsync(queryString, cb, 'school')"-->
                    <!--placeholder="请输入学校名称"-->
                    <!--@select="handleSelect"-->
                    <!--&gt;</el-autocomplete>-->
                </el-form-item>
                <el-form-item prop="majorId">
                    <el-select
                        style="width: 100%"
                        v-model="ruleForm.majorId"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入专业名称"
                        :remote-method="text => this.query(text, 'major')"
                    >
                        <el-option
                            v-for="item in majorOpts"
                            :key="item.majorId"
                            :label="item.majorName"
                            :value="item.majorId"
                        ></el-option>
                    </el-select>
                    <!--<el-autocomplete-->
                    <!--style="width: 100%"-->
                    <!--v-model="ruleForm.major"-->
                    <!--:fetch-suggestions="(queryString, cb) => querySearchAsync(queryString, cb, 'major')"-->
                    <!--placeholder="请输入专业名称"-->
                    <!--@select="handleSelect"-->
                    <!--&gt;</el-autocomplete>-->
                </el-form-item>
                <el-form-item class="ku-login-footer">
                    <el-button class="ku-login-footer-btn" type="primary" @click="updateCompany">寻找组织</el-button>
                </el-form-item>
                <div class="ku-login-skip-regist" @click="visible = false">跳过，晚点再找</div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { searchSchool, searchMajor, saveCompany } from "@/api/company"

export default {
    name: "ku-info",
    data() {
        return {
            ruleForm: {
                schoolId: "",
                majorId: "",
            },
            rules: {
                schoolId: [
                    {
                        required: true,
                        message: "学校名称不能为空",
                        trigger: "change",
                    },
                ],
                majorId: [
                    {
                        required: true,
                        message: "专业名称不能为空",
                        trigger: "change",
                    },
                ],
            },
            schoolOpts: [],
            majorOpts: [],
        }
    },
    computed: {
        visible: {
            get() {
                return this.$store.getters.isShowRegistFrom
            },
            set(val) {
                this.$store.commit("SET_SHOWREGIST", val)
            },
        },
    },
    mounted() {
        if (this.$refs.ruleForm) {
            this.$refs.ruleForm.resetFields()
            this.$refs.ruleForm.clearValidate()
        }
    },
    methods: {
        clearForm() {
            this.ruleForm = {
                schoolId: "",
                majorId: "",
            }
            this.$nextTick(() => {
                if (this.$refs.ruleForm) {
                    this.$refs.ruleForm.resetFields()
                    this.$refs.ruleForm.clearValidate()
                }
            })
        },
        getSchool(val) {
            return searchSchool(val).then(resp => {
                this.schoolOpts = resp.data || []
            })
        },
        getMajor(val) {
            return searchMajor(val).then(resp => {
                this.majorOpts = resp.data || []
            })
        },
        query(queryString, type) {
            if (queryString == "" || queryString == null || !queryString) {
                return []
            }
            if (this.timer) {
                window.clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                if (type == "school") {
                    return this.getSchool(queryString)
                } else if (type == "major") {
                    return this.getMajor(queryString)
                }
            }, 300)
        },
        updateCompany() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { schoolId, majorId } = this.ruleForm
                    const [school] = this.schoolOpts.filter(item => item.schoolId == schoolId)
                    const [major] = this.majorOpts.filter(item => item.majorId == majorId)
                    return saveCompany({ ...school, ...major }).then(() => {
                        this.$store.commit("SET_SHOWREGIST", false)
                        this.$router.push("/reload")
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss">
.ku-login-dialog-info {
    & > .el-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 426px;
        height: 352px;
        transform: translate(-50%, -50%);
        border-radius: 10px;

        .el-dialog__body {
            padding: 0 60px 30px 60px;
        }
    }

    .el-input__inner {
        border-radius: 10px;
        border: 1px solid #979797;
        font-size: 14px;
    }
}
</style>

<style lang="scss" scoped>
.ku-info {
    .ku-login-header {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        text-align: center;
        margin-bottom: 40px;
    }

    .ku-login-footer {
        text-align: center;
        margin-top: 40px;
    }

    .ku-login-footer-btn {
        width: 100%;
        height: 38px;
        background: #24c68b;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        border: none;
    }

    .ku-login-skip-regist {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-top: 10px;
        cursor: pointer;
        text-align: center;
    }
}
</style>
