import request from "@/utils/request"

// 模糊搜索学校信息
export function getTimeline(params) {
    return request({
        url: "/friend/website/image/semiLogin/list",
        method: "get",
        params: params,
    })
}
