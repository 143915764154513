<template>
    <div id="app">
        <router-view />
        <ku-login></ku-login>
        <ku-info></ku-info>
    </div>
</template>

<script>
import KuLogin from "@/views/ku-login/ku-login"
import KuInfo from "@/views/ku-login/ku-info"

export default {
    name: "app",
    components: { KuLogin, KuInfo },
}
</script>

<style lang="scss">
#app {
    // min-width: 1240px;
}

.markdown-body {
    word-break: break-all;
}

body {
    font: status-bar;
    font-size: 14px;
    line-height: 1.42858;
    --el-color-primary: #24c68b;
    --el-color-primary-light-3: rgba(36, 198, 139, 0.7);
    --el-color-primary-light-5: rgba(36, 198, 139, 0.5);
    --el-color-primary-light-7: rgba(36, 198, 139, 0.3);
    --el-color-primary-light-8: rgba(36, 198, 139, 0.2);
    --el-color-primary-light-9: rgba(36, 198, 139, 0.1);
    --el-color-primary-dark-2: #24c68b;
    --el-color-danger: #fb7a75;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
        Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     font-size: 100%;
//     margin: 0;
//     font-weight: 400;
// }

body,
form,
ul,
ol,
dl,
dd,
p {
    margin: 0;
}

ul,
ol {
    list-style-type: none;
    padding: 0;
}

img {
    border: 0 none;
    vertical-align: bottom;
}

button,
input,
select,
textarea {
    font-size: 1em;
    font-family: inherit;
}

a,
a:hover {
    text-decoration: none;
}

.ellipsis {
    overflow: hidden;
    /*超出部分隐藏*/
    white-space: nowrap;
    /*禁止换行*/
    text-overflow: ellipsis;
    /*省略号*/
}

.ellipsis-2 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ellipsis-3 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.el-button {
    border: none;
    border-color: transparent;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
    border-color: transparent;
    border: none;
}

.el-message-box {
    border: none !important;
    border-radius: 12px !important;
    padding-bottom: 44px !important;
}

.el-message-box__header {
    background-color: #fff;
    padding-bottom: 0 !important;
}
.el-message-box__content{
    padding: 0 !important;
    margin-top: 9px;
}
.el-message-box__title {
    font-weight: 600;
    font-size: 18px;
    color: #222222;
    font-style: normal;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before{
        content: " ";
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background: url("@/assets/jinggao.png") left top no-repeat;
        background-size: 100% 100%;
    }
}

.el-message-box__message{
    text-align: center;
    white-space: normal;
    word-break: break-word;
}

// .el-message-box__headerbtn .el-message-box__close {
//     color: #fff !important;
// }

.el-message-box__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    margin-top: 20px;
    .el-button--small:nth-child(1) {
        width: 120px;
        height: 38px;
        border-radius: 4px;
        border: none;
        font-size: 16px;
        color: #222222;
        font-weight: 500;
        margin-left: 20px;
        background: #f7f7f7;
    }

    .el-button--small:nth-child(2) {
        width: 120px;
        height: 38px;
        background: #24c68b;
        border-radius: 4px;
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        border: none;
    }
}

.loading-diy-circle {
    position: relative;
    width: 30px;
    height: 30px;
    border: 2px solid #666;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-right-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    animation: circle infinite 0.75s linear;
    margin-bottom: 20px;
}

@keyframes circle {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.el-radio__input.is-checked+.el-radio__label,
.el-select-dropdown__item.selected,
.el-checkbox__input.is-checked+.el-checkbox__label,
.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close,
.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #24c68b !important;
}

.el-radio__input.is-checked .el-radio__inner,
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner,
.el-pagination.is-background .el-pager li:not(.disabled).active {
    border-color: #24c68b !important;
    background-color: #24c68b !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
    color: #fff !important;
    cursor: pointer;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner,
.el-select .el-input__inner:focus,
.el-checkbox__input.is-focus .el-checkbox__inner,
.el-checkbox__inner:hover,
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
    border-color: #24c68b !important;
}

.cancel-btn {
    border: 1px solid #dcdfe6 !important;
}
</style>
