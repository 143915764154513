import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import ellipsis from "@hyjiacan/vue-ellipsis"
import uweb from "vue-uweb"
import "./theme/index.css"
import "./common.scss"
import RecommendTag from '@/components/RecommendTag/RecommendTag.vue'
Vue.config.productionTip = false

function formatNum(value) {
    let num = (value || 0).toString()
    let result = ""
    const units = ["", "w", "亿", "兆"]
    let index = 0
    while (num.length > 4) {
        result = "." + num.slice(-4) + result
        num = num.slice(0, num.length - 4)
        index++
    }
    const unit = units[index] || ""
    const tail = parseInt(result.substring(1, 2))
    if (tail > 0) {
        return `${num}.${tail}${unit}`
    }
    return `${num}${unit}`
}

Vue.use(ElementUI)
Vue.use(ellipsis)
Vue.use(uweb, "1281313260")
Vue.component('RecommendTag',RecommendTag)
Vue.prototype.$num = formatNum

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app")
