var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ku-main-layout"},[_c('div',{staticClass:"ku-main-layout-header"},[_c('div',{staticClass:"ku-main-layout-bg"}),_c('div',{staticClass:"ku-main-layout-nav"},[_c('ku-navbar')],1)]),_c('div',{staticClass:"ku-main-layout-body"},[_c('KeepAlive',{attrs:{"include":"home-view"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),_c('div',{staticClass:"ku-main-layout-footer",style:({
            height: _vm.showFooter ? '1px' : '260px',
            overflow: 'hidden',
            opacity: _vm.showFooter ? 0 : 1,
        })},[_c('div',{staticClass:"ku-main-layout-footer-body"},[_c('div',{staticClass:"ku-main-layout-footer-top"},[_vm._m(0),_c('div',{staticClass:"ku-main-layout-footer-top-item ku-main-layout-footer-top-item-secd"},[_c('p',{staticClass:"ku-main-layout-text private-text",on:{"click":function($event){$event.stopPropagation();return _vm.gotoPrivate('private')}}},[_vm._v("隐私协议")]),_c('p',{staticClass:"ku-main-layout-text private-text",on:{"click":function($event){$event.stopPropagation();return _vm.gotoPrivate('service')}}},[_vm._v("服务条款")])]),_vm._m(1)]),_vm._m(2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ku-main-layout-footer-top-item ku-main-layout-footer-top-item-first"},[_c('p',{staticClass:"ku-main-layout-text"},[_vm._v("西安库学长信息技术有限公司")]),_c('p',{staticClass:"ku-main-layout-text"},[_vm._v("客服热线：")]),_c('p',{staticClass:"ku-main-layout-value-phone"},[_vm._v("029 8821 8621")]),_c('p',{staticClass:"ku-main-layout-text"},[_vm._v("公司地址：")]),_c('p',{staticClass:"ku-main-layout-value"},[_vm._v("陕西省西安市高新区沣惠南路34号新长安广场c座11层11106室")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ku-main-layout-footer-top-item ku-main-layout-footer-top-item-flex"},[_c('div',{staticClass:"ku-main-layout-weixin-item"},[_c('img',{attrs:{"src":require("@/assets/w3-footer.jpg")}}),_c('div',[_vm._v("添加客服微信")]),_c('div',[_vm._v("了解更多内容")])]),_c('div',{staticClass:"ku-main-layout-weixin-item"},[_c('img',{staticClass:"ku-main-layout-weixin-cycle",attrs:{"src":require("@/assets/public.jpg")}}),_c('div',[_vm._v("扫码关注")]),_c('div',[_vm._v("KU学长公众号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ku-main-layout-footer-bottom"},[_vm._v(" 西安库学长信息技术有限公司 "),_c('a',{staticClass:"ku-main-layout-footer-gov",attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v(" 陕ICP备2023009067号-1 ")])])
}]

export { render, staticRenderFns }