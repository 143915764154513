import request from "@/utils/request"

//  投递列表
export function getReqList(params) {
    return request({
        url: "/friend/user/company/post/list",
        method: "get",
        params: { ...params },
    })
}

//  添加投递（点击我投过了）
export function addReqItem(companyId) {
    return request({
        url: "/friend/user/company/post/add",
        method: "post",
        data: { companyId },
    })
}
// 帮我内推
export function deliveryResumeToSchooleMates(data={}) {
    return request({
        url: "/friend/user/recommend/post/add",
        method: "post",
        data,
    })
}

// 立即投递简历
export function deliveryResumeToCompany(data={}) {
    return request({
        url: "/friend/user/company/post/add",
        method: "post",
        data
    })
}

//热门城市列表
export function hotCityList() {
    return request({
        url: "/friend/city/semiLogin/hot",
        method: "get",
    })
}

// 全部城市列表
export function allCityList() {
    return request({
        url: "/friend/city/semiLogin/list",
        method: "get",
    })
}

//  取消投递
export function removeReqItem(companyId) {
    return request({
        url: "/friend/user/company/post/delete",
        method: "post",
        data: { companyId },
    })
}

//  修改状态
export function updtItemStatus(params) {
    return request({
        url: "/friend/user/company/post/updateStatus",
        method: "post",
        data: { ...params },
    })
}

//  添加面试阶段
export function addReqStage(params) {
    return request({
        url: "/friend/user/company/post/process/add",
        method: "post",
        data: { ...params },
    })
}

//  添加offer
export function addReqOffer(params) {
    return request({
        url: "/friend/user/offer/add",
        method: "post",
        data: { ...params },
    })
}

//  模糊搜索岗位
export function searchMajor(searchValue) {
    return request({
        url: "/friend/post/semiLogin/search",
        method: "get",
        params: { searchValue },
    })
}
export function getAllPostList() {
    return request({
        url: "/system/post/listAll",
        method: "get",
    })
}
//  模糊搜索公司
export function searchCompany(searchValue) {
    return request({
        url: "/friend/company/semiLogin/search",
        method: "get",
        params: { searchValue },
    })
}

//  模糊搜索公司
export function searchCitys(searchValue) {
    return request({
        url: "/friend/city/semiLogin/search",
        method: "get",
        params: { searchValue },
    })
}

//  模糊搜索岗位
export function getOfferDetail(userCompanyPostId) {
    return request({
        url: "/friend/user/offer/detail",
        method: "get",
        params: { userCompanyPostId },
    })
}
// 获取公司下的城市列表
export function getCompanyCity(companyId = "") {
    return request({
        url: "/friend/salary/semiLogin/city/list",
        method: "get",
        params: { companyId },
    })
}
// 获取公司下的城市列表
export function getCompanyPost(companyId = "") {
    return request({
        url: "/friend/salary/semiLogin/post/list",
        method: "get",
        params: { companyId },
    })
}
// 上传图片
export function uploadImg(data) {
    return request({
        url: "/file/upload",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        method: "post",
        data: data,
    })
}
